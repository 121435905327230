import React, { useState } from 'react';
import Select from 'react-select';
import '../styles/DataTable.css';

const DataTable = ({ data, columns }) => {
  // Parse the data properly
  const [filters, setFilters] = useState({
    "Largest City": [],
    "State County": [],
    "Affordability Grade": [],
    "Crime Grade": [],
    "Dense Grade": []
  });
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  // Properly parse the data if needed
  if (data && typeof data.body === 'string') {
    try {
      data = JSON.parse(JSON.parse(data.body));
    } catch (error) {
      console.error('Error parsing data:', error);
      data = null;
    }
  }

  // Ensure data is an array
  if (!Array.isArray(data)) {
    data = [data];
  }

  // Sort the data based on the current sort configuration
  const sortedData = React.useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key !== null) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  if (!data) {
    return <p>No results found</p>;
  }

  // Create a mapping of column names to JSON keys
  const columnKeyMap = {
    "Zipcode": "zipcode",
    "Largest City": "largestCity",
    "State County": "stateCounty",
    "Affordability Grade": "affordGrade",
    "Crime Grade": "crimeGrade",
    "Dense Grade": "denseGrade",
    "Average Winter Lows": "janTemp",
    "Average Summer High": "julyTemp",
    "Costco Distance": "costcoDistance",
    "Airbnb URL": "airbnbUrl"
  };

  // Handle filter changes
  const handleFilterChange = (col, selectedOptions) => {
    setFilters({
      ...filters,
      [col]: selectedOptions.map(option => option.value)
    });
  };

  // Handle filter change for Costco Distance
  const handleCostcoFilterChange = (selectedOption) => {
    setFilters({
      ...filters,
      "Costco Distance": selectedOption.value
    });
  };
  

  // Handle column sorting
  const handleSort = (column, event) => {
    if (event && event.target.closest('.select__control')) return;

    const key = columnKeyMap[column];
    let direction = 'ascending';

    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    setSortConfig({ key, direction });
  };

// Apply filters to the sorted data
const filteredData = sortedData.filter(row => {
  return columns.every(col => {
    if (col === "Costco Distance") {
      const costcoDistance = row[columnKeyMap[col]];
      const hasCostco = costcoDistance !== "No Costco in 30 miles" && costcoDistance !== "Too Remote";
      if (filters["Costco Distance"] === "Yes" && !hasCostco) return false;
      if (filters["Costco Distance"] === "No" && hasCostco) return false;
      return true;
    }

    if (!filters[col] || filters[col].length === 0) return true;
    const key = columnKeyMap[col];
    return filters[col].includes(row[key]);
  });
});

  // Generate options for the dropdown filters
  const generateOptions = (col) => {
    const key = columnKeyMap[col];
    const uniqueValues = [...new Set(data.map(row => row[key]))];
    return uniqueValues.map(value => ({ label: value, value }));
  };

  return (
    <div className="table-container">
      <table className="responsive-table">
        <thead>
          <tr>
            {columns.map((col) => (
              <th key={col} onClick={(e) => handleSort(col, e)}>
                {col}
                {["Largest City", "State County", "Affordability Grade", "Crime Grade", "Dense Grade"].includes(col) && (
                  <Select
                    isMulti
                    options={generateOptions(col)}
                    onChange={(selectedOptions) => handleFilterChange(col, selectedOptions)}
                    placeholder={`Filter`}
                    className="multi-select"
                    classNamePrefix="select"
                  />
                )}
                {col === "Costco Distance" && (
                  <Select
                    options={[
                      { value: 'All', label: 'All' },
                      { value: 'Yes', label: 'Yes' },
                      { value: 'No', label: 'No' }
                    ]}
                    onChange={handleCostcoFilterChange}
                    placeholder="Filter Costco"
                    className="multi-select"
                    classNamePrefix="select"
                  />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredData.length > 0 ? (
            filteredData.map((row, index) => (
              <tr key={index}>
                {columns.map((col) => (
                  <td key={col}>
                    {col === "Airbnb URL" ? (
                      <a href={row[columnKeyMap[col]]} target="_blank" rel="noopener noreferrer">
                        Airbnb Link
                      </a>
                    ) : (
                      row[columnKeyMap[col]] || 'N/A' // Corrected key access
                    )}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length}>No results found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
