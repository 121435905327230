import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import ZipcodeSearch from './components/ZipcodeSearch';
import StateSearch from './components/StateSearch';
import './styles/App.css';
import logo from './styles/edenLogo.png';

function App() {
  useEffect(() => {
    document.title = 'Luxon Homes';

    // Create a link element for the favicon
    const favicon = document.createElement('link');
    favicon.rel = 'icon';
    favicon.href = logo; // Use the imported logo as the favicon
    favicon.type = 'image/png'; // Specify the image type

    // Append the favicon link element to the document head
    document.head.appendChild(favicon);

    // Cleanup function to remove the favicon when the component is unmounted
    return () => {
      document.head.removeChild(favicon);
    };
  }, []);
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/zipcodeSearch" element={<ZipcodeSearch />} />
        <Route path="/stateSearch" element={<StateSearch />} />
      </Routes>
    </Router>
  );
}

export default App;
