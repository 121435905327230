import React from 'react';
import '../styles/Home.css';  // Extract relevant styles for Home into Home.css

const Home = () => {
  return (
    <div className="home-container">
      <h1 className="fade-in">Welcome to Luxon Homes</h1>
      <p className="slide-in">Select a state or enter a zipcode to begin your search for the best areas to live.</p>
      <div className="home-actions zoom-in">
        <a href="/stateSearch" className="btn btn-primary">Search by State</a>
        <a href="/zipcodeSearch" className="btn btn-secondary">Search by Zipcode</a>
      </div>
    </div>
  );
};

export default Home;
