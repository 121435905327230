import React from 'react';
import '../styles/Header.css'; // Extract relevant styles for Header into Header.css
import edenLogo from '../components/edenLogo.png'

const Header = () => (
  <header className="header">
    <div className="brand">
      <img src={edenLogo} alt="Luxon Homes Logo" className="logo" />
      <span className="titleName">Luxon Homes</span>
    </div>
    <nav>
      <ul>
        <li><a href="/">Home</a></li>
        <li><a href="/zipcodeSearch">Zipcode Search</a></li>
        <li><a href="/stateSearch">State Search</a></li>
      </ul>
    </nav>
  </header>
);

export default Header;
