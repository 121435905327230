import React, { useState } from 'react';
import SearchBar from './SearchBar';
import DataTable from './DataTable';
import LoadingSpinner from './LoadingSpinner';
import '../styles/StateSearch.css'; // Import the updated CSS

const StateSearch = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searched, setSearched] = useState(false); // Track if a search has been made

  const handleSearch = async () => {
    setLoading(true);
    const stateName = document.getElementById("searchInput").value;

    if (stateName.length < 2) {
      console.error("ERROR: Invalid State Name");
      setLoading(false);
      setSearched(false); // Ensure the table doesn't appear
      return;
    }

    const raw = JSON.stringify({ "all-zipcode": stateName });
    const requestOptions = {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: raw,
      redirect: 'follow'
    };

    try {
      const response = await fetch("https://fiqro8u1gb.execute-api.us-east-2.amazonaws.com/dev", requestOptions);
      const result = await response.json();
      setData(result);
      setSearched(true); // Indicate that a search has been made
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`search-container fade-in ${searched ? 'moved-up' : ''}`}>
      <SearchBar placeholder="Enter a valid state name" onSearch={handleSearch} />
      {loading ? (
        <LoadingSpinner />
      ) : (
        searched && (
          <DataTable
            data={data}
            columns={[
              "Zipcode",
              "Largest City",
              "State County",
              "Affordability Grade",
              "Crime Grade",
              "Dense Grade",
              "Average Winter Lows",
              "Average Summer High",
              "Costco Distance",
              "Airbnb URL"
            ]}
          />
        )
      )}
    </div>
  );
};

export default StateSearch;
