import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa'; // Import the search icon from react-icons
import '../styles/SearchBar.css'; // Import the updated CSS

const SearchBar = ({ placeholder = "Search...", onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  // Handle input changes
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Handle key press for 'Enter' key
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'NumpadEnter') { // Check for both Enter keys
      onSearch(searchTerm);
    }
  };

  // Handle button click for search
  const handleSearchClick = () => {
    onSearch(searchTerm);
  };

  return (
    <div className="wrap">
      <div className="search">
        <input 
          type="text" 
          id="searchInput" 
          className="searchTerm" 
          value={searchTerm} 
          placeholder={placeholder} 
          onChange={handleInputChange} 
          onKeyDown={handleKeyDown} 
        />
        <button 
          type="button" 
          className="searchButton" 
          onClick={handleSearchClick}
        >
          <FaSearch /> {/* Use the react-icons search icon */}
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
